<template>
  <details :open="false" class="[&_svg.summary]:open:-rotate-0">
    <summary role="button" class="flex items-center gap-2 select-none">
      <span>{{ title }}</span>
      <svg width="13" height="9" viewBox="0 0 13 9" fill="currentcolor"
        class="w-2 summary transition-all duration-100 -rotate-90">
        <path
          d="M10.1611 0.314094L5.99463 4.48054L1.82819 0.314094C1.4094 -0.104698 0.732886 -0.104698 0.314094 0.314094C-0.104698 0.732886 -0.104698 1.4094 0.314094 1.82819L5.24295 6.75705C5.66175 7.17584 6.33825 7.17584 6.75705 6.75705L11.6859 1.82819C12.1047 1.4094 12.1047 0.732886 11.6859 0.314094C11.2671 -0.0939598 10.5799 -0.104698 10.1611 0.314094Z"
          transform="translate(0.77832 0.998535)"></path>
      </svg>
    </summary>

    <slot>
      slot
    </slot>
  </details>
</template>

<script>
export default {
  props: {
    title: {
      default: 'Knopf'
    },
  },
}
</script>
