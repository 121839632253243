<template>
  <div class="flex h-full w-full min-h-full ">
    <main-navigation class="hidden md:block"></main-navigation>
    <div class="flex flex-col min-w-0 flex-grow flex-shrink place-items-stretch h-full">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import MainNavigation from '@/components/navigation/MainNavigation.vue';

export default {
  components: {
    MainNavigation
  },
}
</script>
