<template>
  <Listbox v-model="selectedPerson" class="relative">
    <div class="relative">
      <ListboxButton role="button"
        class="w-full flex items-center justify-between p-2 rounded bg-gray-850/50 hover:bg-gray-850">
        <div class="flex items-center gap-2 max-w-full">
          <div class="font-extrabold rounded bg-gray-400 text-black px-0.5">
            BS
          </div>
          <div class="truncate">{{ selectedPerson.name }}</div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="w-3 h-3">
          <path fill-rule="evenodd"
            d="M5.22 10.22a.75.75 0 0 1 1.06 0L8 11.94l1.72-1.72a.75.75 0 1 1 1.06 1.06l-2.25 2.25a.75.75 0 0 1-1.06 0l-2.25-2.25a.75.75 0 0 1 0-1.06ZM10.78 5.78a.75.75 0 0 1-1.06 0L8 4.06 6.28 5.78a.75.75 0 0 1-1.06-1.06l2.25-2.25a.75.75 0 0 1 1.06 0l2.25 2.25a.75.75 0 0 1 0 1.06Z"
            clip-rule="evenodd" />
        </svg>
      </ListboxButton>

      <ListboxOptions
        class="absolute z-10 mt-1 bottom-10 max-h-60 w-full overflow-auto rounded-md bg-gray-850 border border-gray-750/80 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
        <ListboxOption v-for="person in people" :key="person.id" :value="person" role="button"
          class="flex items-center gap-2 p-1 hover:bg-gray-900">
          <div class="font-extrabold rounded bg-gray-400 text-black px-0.5">BS</div>
          <div class="truncate">{{ person.name }}</div>
        </ListboxOption>
      </ListboxOptions>
    </div>
  </Listbox>
</template>

<script setup>
import { ref } from 'vue'
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue'

const people = [
  { id: 1, name: 'byte.software' },
  { id: 2, name: 'ELISANA Digital GmbH' },
  { id: 3, name: 'WhisteWatch' },
  { id: 4, name: 'Backspace' },
  { id: 5, name: 'Microsoft Enterprise Spaceship Ltd.' },
]
const selectedPerson = ref(people[0])
</script>
