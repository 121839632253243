import { createApp } from 'vue'
import store from '@/stores';
import router from '@/router';
import App from '@/layouts/App.vue';
import '../css/app.css';
import axios from 'axios';

window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['Content-Type'] = 'application/json';
window.axios.defaults.headers.common['Accept'] = 'application/json';
window.axios.defaults.withCredentials = true;
window.axios.defaults.withXSRFToken = true;

store.dispatch('attempt_user')
  .catch((error) => {
    if (error.response?.status !== 401) {
      console.error(error)
    }
  })
  .finally(() => {
    const app = createApp(App)
      .use(store)
      .use(router);

    app.mount('#app');
  })
