import { createRouter, createWebHistory } from "vue-router"
import store from '@/stores'

import Guest from '@/layouts/Guest.vue'
import NotFound from '@/pages/NotFound.vue'
import Register from '@/pages/auth/Register.vue'
import Login from '@/pages/auth/Login.vue'
import TwoFactorChallenge from '@/pages/auth/TwoFactorChallenge.vue'
import ForgotPassword from '@/pages/auth/ForgotPassword.vue'
import ResetPassword from '@/pages/auth/ResetPassword.vue'
import VerifyEmail from '@/pages/auth/VerifyEmail.vue'

import Authenticated from '@/layouts/Authenticated.vue'
import Activities from '@/pages/Activities.vue'
import Projects from '@/pages/Projects.vue'
import ProjectDetails from '@/pages/ProjectDetails.vue'
import Domains from '@/pages/Domains.vue'
import Servers from '@/pages/Servers.vue'
// import ConfirmPassword from '@/js/pages/auth/ConfirmPassword'
// import User from '@/js/pages/User'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/auth',
      redirect: "/login",
      component: Guest,
      meta: { isGuest: true },
      children: [
        { path: "/register", name: 'Register', component: Register },
        { path: "/login", name: 'Login', component: Login },
        { path: "/verify-email", name: 'VerifyEmail', component: VerifyEmail },
        { path: "/two-factor-challenge", name: 'TwoFactorChallenge', component: TwoFactorChallenge },
        { path: "/forgot-password", name: 'ForgotPassword', component: ForgotPassword },
        { path: "/reset-password/:token", name: 'ResetPassword', component: ResetPassword }
      ]
    },
    {
      path: '/',
      component: Authenticated,
      meta: { requiresAuth: true },
      children: [
        { path: "/", name: 'Activity', component: Activities },
        { path: "/projects", name: 'Projects', component: Projects },
        { path: "/projects/:project", name: 'ProjectDetails', component: ProjectDetails },
        { path: "/domains", name: 'Domains', component: Domains },
        { path: "/servers", name: 'Servers', component: Servers },
        // { path: "/user", name: 'User', component: User },
        // { path: "/confirm-password", name: 'ConfirmPassword', component: ConfirmPassword },
      ]
    },

    {
      path: '/:pathMatch(.*)*',
      name: '404',
      component: NotFound,
    }
  ],
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !store.getters.user) {
    next({ name: "Login" })
  } else if (to.meta.isGuest && store.getters.user) {
    next({ name: "Activity" })
  } else {
    next();
  }

  // next();
});

export default router;
